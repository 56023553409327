import React, { useEffect } from 'react';


export default function DummyComponent({pointA}) {
    useEffect(() => {
        console.log(
          "On Dummy Component" + pointA
        );
      }, []);

    return (
        <>
        <h3>Point A : {pointA}</h3>
        </>
    );
}
