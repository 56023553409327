import React, { useEffect, useState } from 'react';
import DummyComponent from '../components/DummyComponent';


export default function NewHome() {
  const [pointA, setPointA] = useState(1);
  const [pointB, setPointB] = useState(2);

  useEffect(() => {
    console.log(
      "Test page component did mount"
    );
    setPointA(3)
    setPointB(3)
  }, []);

  return (
    <>
      <DummyComponent pointA={pointA} />
    </>
    
  );
}
